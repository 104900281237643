import { gql, useQuery } from '@apollo/client'
import { Screens } from 'src/types'

const getScreensAndSlideshowsQuery = gql`
  query getScreensAndSlideshows {
    screens {
      id
      name
      slideshow {
        id
        name
        slides {
          media {
            id
            url
            thumbnailUrl
          }
        }
      }
    }
  }
`

interface GetScreensAndSlideshowsResult {
  screens: Screens
}

export function useScreens() {
  return useQuery<GetScreensAndSlideshowsResult>(getScreensAndSlideshowsQuery)
}
