import { ReactNode, useCallback, useRef } from 'react'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useClickAway, useEvent, useLockBodyScroll } from 'react-use'
import styled from 'styled-components'
import { device } from 'src/device'
import { Box } from './Box'
import { Overlay } from './Overlay'

const StyledOverlay = styled(Overlay)<FullscreenProps>`
  padding: 10px;

  @media ${device.tablet} {
    ${(props) =>
      props.fullscreenOnMobile &&
      `  
      padding: 0;
      `};
  }
`

const StyledBox = styled(Box)<FillProps & FullscreenProps & AutoSizeProps>`
  ${(props) =>
    !props.autoSize &&
    `
    max-width: 70%;
    max-height: 70%;
    `}

  ${(props) =>
    props.fillSpace &&
    `  
    width: 100%;
    height: 100%;
    `}

  @media ${device.tablet} {
    max-width: 100%;
    max-height: 100%;

    ${(props) =>
      props.fullscreenOnMobile &&
      `  
      border-radius: 0;
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      `}
  }
`

interface FillProps {
  fillSpace: boolean
}

interface AutoSizeProps {
  autoSize: boolean
}

interface FullscreenProps {
  fullscreenOnMobile: boolean
}

const CloseIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: ${(props) => props.theme.colors.grayMedium};
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
`

interface ContentProps {
  topPadding: boolean
}

const Content = styled.div<ContentProps>`
  padding-top: ${(props) => (props.topPadding ? '15px' : '0')};
`

export interface ModalProps {
  children: ReactNode
  onClose?: () => void
  showCloseButton?: boolean
  fillSpace?: boolean
  fullscreenOnMobile?: boolean
  scrollable?: boolean
  autoSize?: boolean
  className?: string
}

export function Modal({
  children,
  onClose,
  showCloseButton = false,
  fillSpace = false,
  autoSize = false,
  fullscreenOnMobile = false,
  scrollable = false,
  className,
  ...props
}: ModalProps) {
  const boxRef = useRef(null)
  useClickAway(boxRef, () => {
    onClose?.()
  })

  const handleESC = useCallback(
    (event: KeyboardEvent) => event.key === 'Escape' && onClose?.(),
    [onClose]
  )
  useEvent('keydown', handleESC)

  useLockBodyScroll(true)

  return (
    <StyledOverlay fullscreenOnMobile={fullscreenOnMobile} {...props}>
      <StyledBox
        ref={boxRef}
        fillSpace={fillSpace}
        fullscreenOnMobile={fullscreenOnMobile}
        smallPaddingOnMobile
        scrollable={scrollable}
        autoSize={autoSize}
        shadow
        className={className}
      >
        {showCloseButton && (
          <CloseIcon icon={faTimes} onClick={onClose} data-test="close-icon" />
        )}
        <Content topPadding={showCloseButton}>{children}</Content>
      </StyledBox>
    </StyledOverlay>
  )
}
