import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AnimatingModal } from 'src/elements/AnimatingModal'
import { SubTitle } from 'src/elements/SubTitle'
import { TitleH2 } from 'src/elements/TitleH2'
import { MediaLibrary } from './MediaLibrary'

const Header = styled.div`
  margin-bottom: 40px;
`

const StyledMediaLibrary = styled(MediaLibrary)`
  margin-bottom: -10px;
`

export interface MediaLibraryModalProps {
  show: boolean
  onClose?: () => void
  onFileSelected?: (fileId: string) => void
}

export function MediaLibraryModal({
  show,
  onClose,
  onFileSelected,
}: MediaLibraryModalProps) {
  const { t } = useTranslation()
  return (
    <AnimatingModal
      fillSpace
      fullscreenOnMobile
      scrollable
      show={show}
      showCloseButton
      onClose={onClose}
      data-test="media-library-modal"
    >
      <Header>
        <TitleH2>{t('mediaLibrary.modal.title')}</TitleH2>
        <SubTitle>{t('mediaLibrary.modal.subtitle')}</SubTitle>
      </Header>
      <StyledMediaLibrary onFileSelected={onFileSelected} />
    </AnimatingModal>
  )
}
