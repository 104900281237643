import { ReactElement, useCallback, useState } from 'react'
import {
  DndContext,
  closestCenter,
  PointerSensor,
  DragEndEvent,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { useOpener } from 'src/hooks/useOpener'
import { MediaLibraryModal } from 'src/media/MediaLibraryModal'
import { Slides } from 'src/types'
import { SortableItem } from './SortableItem'

export interface Props {
  slides: Slides
  onSortEnd: (oldIndex: number, newIndex: number) => any
  onChangeMedia: (index: number, mediaId: string) => any
  onDelete: (index: number) => any
}

export function SortablePlaylist({
  slides,
  onSortEnd,
  onChangeMedia,
  onDelete,
}: Props): ReactElement {
  const sensors = useSensors(useSensor(PointerSensor))

  const items = slides.map((slide) => slide.id)

  function handleDragEnd(event: DragEndEvent) {
    document.body.className = ''
    const { active, over } = event

    if (over && active.id !== over.id) {
      const oldIndex = slides.findIndex((slide) => slide.id === active.id)
      const newIndex = slides.findIndex((slide) => slide.id === over.id)

      onSortEnd(oldIndex, newIndex)
    }
  }

  function handleDragCancel() {
    document.body.className = ''
  }

  function handleDragStart() {
    document.body.className = 'disable-select'
  }

  const deleteItem = useCallback(
    (id: string) => {
      const index = slides.findIndex((slide) => slide.id === id)

      if (index !== -1) {
        onDelete(index)
      }
    },
    [slides, onDelete]
  )

  const [currentIndex, setCurrentIndex] = useState<number | null>(null)

  const [isMediaLibraryOpened, openMediaLibrary, closeMediaLibrary] =
    useOpener('edit')

  const onSelectImage = useCallback(
    (index: number) => {
      setCurrentIndex(index)
      openMediaLibrary()
    },
    [setCurrentIndex, openMediaLibrary]
  )

  const onFileSelected = useCallback(
    (mediaId: string) => {
      closeMediaLibrary()
      if (currentIndex !== null) {
        onChangeMedia(currentIndex, mediaId)
      }
    },
    [currentIndex, closeMediaLibrary, onChangeMedia]
  )

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
        onDragStart={handleDragStart}
      >
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          {items.map((id, index) => {
            const slide = slides.find((slide) => slide.id === id)
            return (
              slide && (
                <SortableItem
                  key={id}
                  id={id}
                  slide={slide}
                  data-test="item"
                  onDelete={deleteItem}
                  onSelectImage={() => onSelectImage(index)}
                />
              )
            )
          })}
        </SortableContext>
      </DndContext>
      <MediaLibraryModal
        show={isMediaLibraryOpened}
        onFileSelected={onFileSelected}
        onClose={closeMediaLibrary}
      />
    </>
  )
}
