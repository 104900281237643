import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from 'src/elements/Box'
import { FormGroup } from 'src/elements/form/FormGroup'
import { Input } from 'src/elements/form/Input'
import { Label } from 'src/elements/form/Label'
import { Slider } from 'src/elements/form/Slider'
import { useRenameSlideshow } from 'src/hooks/api/mutations/useRenameSlideshow'
import { useDebouncedInput } from 'src/hooks/useDebouncedInput'
import { useDebouncedValue } from 'src/hooks/useDebouncedValue'
import { Slideshow } from 'src/types'
import { useSetSlideDuration } from './useSetSlideDuration'

const DEFAULT_SLIDE_DURATION_MS = 30000
export interface Props {
  playlist?: Slideshow
}

export function Settings({ playlist }: Props): ReactElement {
  const { t } = useTranslation()
  const { name: initialName, slideDurationMs: initialSlideDurationMs } =
    playlist || {}

  const [renameSlideshowMutation] = useRenameSlideshow()

  const onNameChange = useCallback(
    async (name: string) => {
      if (playlist) {
        await renameSlideshowMutation({
          variables: {
            id: playlist.id,
            name,
          },
        })
      }
    },
    [playlist, renameSlideshowMutation]
  )

  const [setSlideDurationMutation] = useSetSlideDuration()

  const onSlideDurationMs = useCallback(
    async (durationMs: number) => {
      if (playlist) {
        await setSlideDurationMutation({
          variables: {
            id: playlist.id,
            durationMs,
          },
        })
      }
    },
    [playlist, setSlideDurationMutation]
  )

  const [name, onNameChangeEvent, nameSaving] = useDebouncedInput(
    initialName,
    onNameChange
  )

  const [slideDurationMs, handleSlideDurationMsChange, slideDurationSaving] =
    useDebouncedValue(initialSlideDurationMs, onSlideDurationMs, 500)

  return (
    <Box>
      <FormGroup>
        <Label loading={nameSaving}>
          {t('playlists.edit.settings.name.label')}
        </Label>
        <Input
          name="name"
          value={name ?? ''}
          onChange={onNameChangeEvent}
          disabled={initialName === null}
        />
      </FormGroup>
      <FormGroup noMargin>
        <Label loading={slideDurationSaving}>
          {t('playlists.edit.settings.slideDuration.label')}
        </Label>
        <Slider
          value={slideDurationMs ?? DEFAULT_SLIDE_DURATION_MS}
          onChange={handleSlideDurationMsChange}
          values={[5000, 10000, 15000, 20000, 25000, 30000, 45000, 60000]}
          labels={{
            5000: '5',
            10000: '10',
            15000: '15',
            20000: '20',
            25000: '25',
            30000: '30',
            45000: '45',
            60000: '60',
          }}
        />
      </FormGroup>
    </Box>
  )
}
