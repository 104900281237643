interface FeatureFlagConfig {
  appName: string
  host: string
  instanceId: string
}

export interface Config {
  environment: string
  userPoolId: string
  adminUserPoolClientId: string
  region: string
  oldGraphQLEndpoint: string
  mediaOrigin: string
  gtmId: string
  gtmAuth: string
  gtmEnvironment: string
  featureFlags: FeatureFlagConfig
  sentryDsn: string
}

let config: Config | undefined

export async function getConfig() {
  if (!config) {
    const configResponse = await fetch(`/config.json`, {
      cache: 'no-cache',
    })
    config = (await configResponse.json()) as Config
  }
  return config
}
