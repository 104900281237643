import { faArrowCircleRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Box } from 'src/elements/Box'
import { SubTitle } from 'src/elements/SubTitle'
import { TitleH3 } from 'src/elements/TitleH3'

const StyledBox = styled(Box)`
  cursor: pointer;
`

const CenterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5px;
  height: 100%;
`

const Image = styled.img`
  height: 100px;
  margin-bottom: 30px;
`

const StyledSubTitle = styled(SubTitle)`
  margin-bottom: 30px;
  flex: 1;
`

const StyledText = styled.p`
  margin: 0;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  font-family: ${({ theme }) => theme.font.secondary};
  color: ${(props) => props.theme.colors.primary};
`

const Icon = styled(FontAwesomeIcon)`
  margin-left: 5px;
`

export type CardProps = {
  image: string
  title: string
  description: string
  buttonTitle: string
  buttonLink: string
}

export function Card({
  image,
  title,
  description,
  buttonTitle,
  buttonLink,
  ...props
}: CardProps) {
  const navigate = useNavigate()

  function handleClick() {
    navigate(buttonLink)
  }
  return (
    <StyledBox bigRadius {...props} onClick={handleClick}>
      <CenterWrapper>
        <Image src={image} alt={title} />
        <TitleH3>{title}</TitleH3>
        <StyledSubTitle>{description}</StyledSubTitle>
        <StyledText>
          {buttonTitle}
          <Icon icon={faArrowCircleRight} />
        </StyledText>
      </CenterWrapper>
    </StyledBox>
  )
}
