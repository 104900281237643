import { useCallback, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components'
import { Box } from './Box'
import { Fader } from './Fader'

const Container = styled.div`
  position: sticky;
  bottom: 45px;
  height: 0;
`

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 15px;
  max-width: 100%;
  width: fit-content;
  pointer-events: none;
`

export interface PopupProps {
  children: JSX.Element
  show?: boolean
  onHide?: () => void
}

export function Popup({ children, show = true, onHide }: PopupProps) {
  const faderRef = useRef<HTMLDivElement>(null)

  const onExited = useCallback(() => onHide?.(), [onHide])

  return (
    <Container>
      <Wrapper>
        <CSSTransition
          appear
          in={show}
          nodeRef={faderRef}
          onExited={onExited}
          addEndListener={(done) => {
            if (faderRef.current !== null) {
              faderRef.current.addEventListener('transitionend', done, false)
            }
          }}
        >
          <Fader ref={faderRef} duration={500}>
            <Box shadow shiftShadow>
              {children}
            </Box>
          </Fader>
        </CSSTransition>
      </Wrapper>
    </Container>
  )
}
