import { gql, useQuery } from '@apollo/client'
import { MediaFiles } from 'src/types'

const getAllMediaQuery = gql`
  query getAllMedia {
    getAllMedia {
      id
      url
      thumbnailUrl
      created
    }
  }
`

interface GetAllMediaResult {
  getAllMedia: MediaFiles
}

export function useAllMedia() {
  return useQuery<GetAllMediaResult>(getAllMediaQuery)
}
