import { useRef } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import styled from 'styled-components'
import { Fader } from 'src/elements/Fader'
import { Modal, ModalProps } from 'src/elements/Modal'

const StyledFader = styled(Fader)`
  z-index: 1000;
  position: fixed;
`

export interface AnimatingModalProps extends ModalProps {
  show: boolean
  animatingKey?: string
}

const duration = 250

export function AnimatingModal({
  show,
  animatingKey,
  children,
  ...props
}: AnimatingModalProps) {
  const faderRef = useRef<HTMLDivElement>(null)
  return (
    <TransitionGroup>
      {show && (
        <CSSTransition
          appear
          mountOnEnter
          unmountOnExit
          nodeRef={faderRef}
          key={animatingKey}
          addEndListener={(done) => {
            if (faderRef.current !== null) {
              faderRef.current.addEventListener('transitionend', done, false)
            }
          }}
        >
          <StyledFader ref={faderRef} duration={duration}>
            <Modal {...props}>{children}</Modal>
          </StyledFader>
        </CSSTransition>
      )}
    </TransitionGroup>
  )
}
