import { gql, useApolloClient, useMutation } from '@apollo/client'
import { SlideshowId, SlidesInput } from 'src/types'

const editSlideshow = gql`
  mutation editSlides($id: ID!, $slides: [SlideInput!]!) {
    editSlides(id: $id, slides: $slides) {
      id
      slides {
        id
        media {
          id
          url
          thumbnailUrl
        }
      }
    }
  }
`

export type EditSlideData = {
  id: SlideshowId
  slides: SlidesInput
}

export function useEditSlides() {
  const client = useApolloClient()

  return useMutation(editSlideshow, {
    optimisticResponse: ({ id, slides }: EditSlideData) => {
      return {
        editSlides: {
          id,
          slides: slides.map((slide) => ({
            id: slide.id,
            media: client.readFragment({
              id: `Media:${slide.mediaId}`,
              fragment: gql`
                fragment _ on Media {
                  id
                  url
                  thumbnailUrl
                }
              `,
            }),
          })),
          __typename: 'Slideshow',
        },
      }
    },
  })
}
