import { ReactElement } from 'react'
import { faPhotoVideo } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components'
import { Fader } from 'src/elements/Fader'
import { OutlineBox } from 'src/elements/OutlineBox'
import { Slides } from 'src/types'
import { usePlaylistMachine } from './usePlaylistMachine'

const Container = styled.div`
  padding-top: ${(9 / 16) * 100}%;
  position: relative;
`

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const StyledOutlineBox = styled(OutlineBox)`
  height: 100%;
  color: ${({ theme }) => theme.colors.blue};
`

const PlaceholderIcon = styled(FontAwesomeIcon)`
  font-size: 60px;
  margin-bottom: 20px;
`

const StyledFader = styled(Fader)`
  width: 100%;
  height: 100%;
  position: absolute;
`

const SlideWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: #000;
`

const SlideImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
`

export interface Props {
  slides?: Slides
  slideDurationMs?: number
}

const SLIDE_MILLISECONDS = 30000
const TRANSITION_MILLISECONDS = 2000

export function Preview({ slides, slideDurationMs }: Props): ReactElement {
  const { t } = useTranslation()
  const currentIndex = usePlaylistMachine(
    slides?.length ?? 0,
    slideDurationMs ?? SLIDE_MILLISECONDS
  )

  return (
    <Container>
      <Wrapper>
        {slides ? (
          <SlideWrapper>
            {slides.map((slide, index) => (
              <CSSTransition
                key={index}
                in={index === currentIndex}
                appear
                addEndListener={(node, done) => {
                  node.addEventListener('transitionend', done, false)
                }}
              >
                <StyledFader duration={TRANSITION_MILLISECONDS}>
                  {slide.media && <SlideImage src={slide.media.url} />}
                </StyledFader>
              </CSSTransition>
            ))}
          </SlideWrapper>
        ) : (
          <StyledOutlineBox>
            <PlaceholderIcon icon={faPhotoVideo} />
            <div>{t('playlists.edit.preview.placeholderText')}</div>
          </StyledOutlineBox>
        )}
      </Wrapper>
    </Container>
  )
}
