import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { TitleH2 } from 'src/elements/TitleH2'
import { useScrollToTop } from 'src/hooks/useScrollToTop'
import { FilePreviewModal } from './FilePreviewModal'
import { MediaLibrary } from './MediaLibrary'
import { useSelectFile } from './useSelectFile'

const StyledHeader = styled.div`
  margin-bottom: 40px;
`

export function MediaLibraryScreen() {
  const { t } = useTranslation()
  useScrollToTop()

  const { selectedFile, selectFileById, deselectFile } = useSelectFile()

  return (
    <div>
      <StyledHeader>
        <TitleH2>{t('mediaLibrary.title')}</TitleH2>
      </StyledHeader>
      <MediaLibrary onFileSelected={selectFileById} />
      <FilePreviewModal file={selectedFile} onClose={deselectFile} />
    </div>
  )
}
