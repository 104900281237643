import { gql, useQuery } from '@apollo/client'
import { Slideshow, SlideshowId } from 'src/types'

const getSlideshow = gql`
  query getSlideshow($id: ID!) {
    slideshow(id: $id) {
      id
      name
      slideDurationMs
      slides {
        id
        media {
          id
          url
          thumbnailUrl
        }
      }
    }
  }
`

interface SlideshowResult {
  slideshow: Slideshow
}

export function useSlideshow(id: SlideshowId) {
  return useQuery<SlideshowResult>(getSlideshow, {
    variables: { id },
  })
}
