import styled from 'styled-components'

export type FaderProps = {
  duration: number
}

export const Fader = styled.div<FaderProps>`
  transition: opacity ${(props) => props.duration}ms linear;
  opacity: 0;
  pointer-events: none;
  position: relative;

  &.appear-active,
  &.appear-done,
  &.enter-active,
  &.enter-done,
  &.exit {
    opacity: 1;
    pointer-events: auto;
  }

  &.exit-active,
  &.exit-done {
    opacity: 0;
  }

  &.exit-active {
    pointer-events: auto;
  }
`
