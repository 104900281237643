import { gql, useQuery } from '@apollo/client'
import { Slideshows } from 'src/types'

const getSlideshowsQuery = gql`
  query getSlideshowsQuery {
    slideshows {
      id
      name
    }
  }
`

interface SlideshowsResult {
  slideshows: Slideshows
}

export function useSlideshows() {
  return useQuery<SlideshowsResult>(getSlideshowsQuery)
}
