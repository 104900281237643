import { ReactElement, useCallback } from 'react'
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { Card } from 'src/components/Card'
import { Slideshow } from 'src/types'

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
`

const Left = styled.div`
  flex: 1;
  width: 50%;
  margin: 0 10px;
`

const Right = styled.div`
  margin: 0 10px;
`

const Title = styled.div`
  color: ${(props) => props.theme.colors.darkBlue};
  font-size: 16px;
  line-height: 24px;
  font-family: ${({ theme }) => theme.font.secondary};
  font-weight: 800;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`

const IconWrapper = styled.div`
  display: flex;
  margin: 0 -15px;
`

const Icon = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: ${(props) => props.theme.colors.primary};
  margin: 0 15px;
  cursor: pointer;
`

export interface PlaylistItemProps {
  playlist: Slideshow
  onDelete?: () => void
  onSelect?: (playlistId: string) => void
}

export function PlaylistItem({
  playlist,
  onDelete,
  onSelect,
}: PlaylistItemProps): ReactElement {
  const { id, name, slides } = playlist
  const image =
    slides?.find((slide) => slide.media)?.media?.thumbnailUrl ?? null

  const onClick = useCallback(() => {
    onSelect?.(id)
  }, [id, onSelect])
  return (
    <Card
      data-test="playlist-card"
      showPlaceholderIcon
      image={image}
      onClick={onClick}
    >
      <ContentWrapper>
        <Left>
          <Title>{name}</Title>
        </Left>
        <Right>
          <IconWrapper>
            {onDelete && <Icon icon={faTrashAlt} onClick={onDelete} />}
          </IconWrapper>
        </Right>
      </ContentWrapper>
    </Card>
  )
}
