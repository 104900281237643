import { createPortal } from 'react-dom'
import { Popup } from 'src/elements/Popup'
import { UploadStatus, UploadStatusProps } from './UploadStatus'
import { useShowProgressPopup } from './useShowProgressPopup'

export interface UploadStatusPopupProps extends UploadStatusProps {
  onHide?: () => void
}

export function UploadStatusPopup(props: UploadStatusPopupProps) {
  const { progress, fileCount, onHide } = props

  const show = useShowProgressPopup(progress, fileCount)

  return createPortal(
    <Popup show={show} onHide={onHide}>
      <UploadStatus {...props} />
    </Popup>,
    document.getElementsByClassName('content')[0]
  )
}
