import { ReactElement, useCallback } from 'react'
import { arrayMove } from '@dnd-kit/sortable'
import { v4 } from 'uuid'
import { useEditSlides } from 'src/hooks/api/mutations/useEditSlides'
import { useSlideshow } from 'src/hooks/api/query/useSlideshow'
import { Slideshow, SlideshowId } from 'src/types'
import { NewPlaylist } from './NewPlaylist'
import { SortablePlaylist } from './SortablePlaylist'

export interface Props {
  id: SlideshowId
}

export function Editor({ id }: Props): ReactElement {
  const playlistQuery = useSlideshow(id)
  const playlist: Slideshow | undefined = playlistQuery.data?.slideshow

  const { slides } = playlist || {}

  const [editSlides, editSlidesResult] = useEditSlides()

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    if (oldIndex !== newIndex && slides) {
      const slidesInput: any = slides.map((slide) => ({
        id: slide.id,
        mediaId: slide.media?.id ?? null,
      }))

      editSlides({
        variables: {
          id,
          slides: arrayMove(slidesInput, oldIndex, newIndex),
        },
      })
    }
  }

  const onChangeMedia = useCallback(
    (index: number, mediaId: string) => {
      if (slides) {
        const slidesInput: any = slides.map((slide, slideIndex) => ({
          id: slide.id,
          mediaId: slideIndex === index ? mediaId : (slide.media?.id ?? null),
        }))

        editSlides({
          variables: {
            id,
            slides: slidesInput,
          },
        })
      }
    },
    [id, slides, editSlides]
  )

  const deleteItem = useCallback(
    (deleteIndex: number) => {
      if (slides) {
        const slidesInput: any = slides
          .filter((_, index) => index !== deleteIndex)
          .map((slide) => ({
            id: slide.id,
            mediaId: slide.media?.id ?? null,
          }))

        editSlides({
          variables: {
            id,
            slides: slidesInput,
          },
        })
      }
    },
    [id, slides, editSlides]
  )

  const addItem = useCallback(
    (mediaId: string) => {
      if (slides) {
        const slidesInput: any = [
          ...slides.map((slide) => ({
            id: slide.id,
            mediaId: slide.media?.id ?? null,
          })),
          {
            id: v4(),
            mediaId,
          },
        ]

        editSlides({
          variables: {
            id,
            slides: slidesInput,
          },
        })
      }
    },
    [id, slides, editSlides]
  )

  return (
    <div data-saving={editSlidesResult.loading}>
      {slides && (
        <SortablePlaylist
          slides={slides}
          onSortEnd={onSortEnd}
          onChangeMedia={onChangeMedia}
          onDelete={deleteItem}
        />
      )}
      <NewPlaylist onAddMedia={addItem} />
    </div>
  )
}
