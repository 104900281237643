import { useContext, useEffect, useState } from 'react'
import { FlagsContext } from 'react-unleash-flags'

export function useIsFlagsLoaded() {
  // useFlag hook sets a state so there is a mismatch if we are not storing isLoaded in state
  const [isLoaded, setLoaded] = useState(false)
  const flagClient = useContext(FlagsContext)

  const isLoadedCurrently = !!flagClient?.getFlags()

  useEffect(() => {
    setLoaded(isLoadedCurrently)
  }, [isLoadedCurrently, setLoaded])

  return isLoaded
}
