import { useCallback, useEffect, useState } from 'react'
import { useAllMedia } from 'src/hooks/api/query/useAllMedia'
import { MediaFile, MediaFiles } from 'src/types'
import { useUpload } from './useUpload'

export function useMediaLibrary() {
  const allMediaQuery = useAllMedia()

  const uploadedFiles: MediaFiles | undefined = allMediaQuery.data?.getAllMedia

  const [uploadingFiles, setUploadingFiles] = useState<MediaFiles>([])

  const addFile = useCallback(
    (file: MediaFile) => setUploadingFiles((files) => [...files, file]),
    [setUploadingFiles]
  )

  useEffect(() => {
    setUploadingFiles((files) =>
      files.filter(
        (file) =>
          !uploadedFiles?.find((uploadedFile) => uploadedFile.id === file.id)
      )
    )
  }, [setUploadingFiles, uploadedFiles])

  const files = uploadedFiles
    ? [
        ...uploadingFiles.filter(
          (file) =>
            !uploadedFiles?.find((uploadedFile) => uploadedFile.id === file.id)
        ),
        ...uploadedFiles,
      ]
    : uploadingFiles

  const { uploadFiles, fileCount, finishedFileCount, progress, resetProgress } =
    useUpload(addFile)

  return {
    isLoaded: allMediaQuery.data !== undefined,
    uploadFiles,
    files,
    fileCount,
    finishedFileCount,
    progress,
    resetProgress,
  }
}
