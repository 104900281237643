import { gql, useApolloClient, useMutation } from '@apollo/client'

const addSlideshowMutation = gql`
  mutation addSlideshow($name: String!) {
    addSlideshow(name: $name) {
      id
      name
    }
  }
`

interface AddSlideshowResult {
  addSlideshow: {
    id: string
    name: string
  }
}

export type AddSlideshowData = {
  name: string
}

export function useAddSlideshow() {
  const client = useApolloClient()

  return useMutation<AddSlideshowResult>(addSlideshowMutation, {
    onCompleted: () => {
      client.refetchQueries({
        include: 'active',
      })
    },
  })
}
