import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { SubTitle } from 'src/elements/SubTitle'
import { TitleH2 } from 'src/elements/TitleH2'
import { useScreens } from 'src/hooks/api/query/useScreens'
import { useScrollToTop } from 'src/hooks/useScrollToTop'
import { Screens as ScreensType } from 'src/types'
import { ScreenList } from './ScreenList'

const Header = styled.div`
  margin-bottom: 60px;
`

export function Screens() {
  useScrollToTop()

  const { t } = useTranslation()
  const screensQuery = useScreens()

  const screens: ScreensType | undefined = screensQuery.data?.screens

  return (
    <div>
      <Header>
        <TitleH2>{t('screens.title')}</TitleH2>
        <SubTitle>
          {screens ? (
            t('screens.subtitle', {
              count: screens.length,
            })
          ) : (
            <>&nbsp;</>
          )}
        </SubTitle>
      </Header>
      <ScreenList screens={screens} />
    </div>
  )
}
