import { gql, useMutation } from '@apollo/client'

const setSlideDurationMutation = gql`
  mutation setSlideDuration($id: ID!, $durationMs: Int!) {
    setSlideDuration(id: $id, durationMs: $durationMs) {
      id
      slideDurationMs
    }
  }
`

interface Variables {
  id: string
  durationMs: number
}

export function useSetSlideDuration() {
  return useMutation<any, Variables>(setSlideDurationMutation)
}
