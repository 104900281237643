import { Client, WebSocket as MockWebSocket, Server } from 'mock-socket'
import { graphql } from 'msw'
import { Media } from './types'

interface Subscription {
  socket: Client
  variables: any
  id: string
}

export function query<TData extends Record<string, any>>(
  operation: string,
  data: TData | ((variables: any) => TData),
  delay?: number
) {
  return graphql.query(operation, (req, res, ctx) => {
    const calculatedData: TData =
      data instanceof Function ? data(req.variables) : data
    return res(ctx.delay(delay), ctx.data(calculatedData))
  })
}

export function noResponse(operation: string) {
  return graphql.query(operation, (req, res, ctx) => {
    return res(ctx.delay('infinite'))
  })
}

export function mutation<TData extends Record<string, any>>(
  operation: string,
  data: (variables: any) => TData,
  delay?: number
) {
  return graphql.mutation(operation, (req, res, ctx) => {
    const calculatedData = data(req.variables)

    return res(ctx.delay(delay), ctx.data(calculatedData))
  })
}

export interface MockServer {
  onMediaChanged: (media: Media) => void
}

let mockServer: MockServer | null = null

function createMockServer(url: string) {
  window.WebSocket = MockWebSocket

  const server = new Server(url)

  const subscriptions: Subscription[] = []

  function onMediaChanged(media: Media) {
    subscriptions.forEach(({ id, variables, socket }) => {
      if (variables.mediaId !== media.id) {
        return
      }
      const message = {
        id,
        type: 'next',
        payload: {
          data: { onMediaChanged: media },
        },
      }
      socket.send(JSON.stringify(message))
    })
  }

  server.on('connection', (socket) => {
    socket.on('message', (data) => {
      const message = JSON.parse(data.toString())
      if (message.type === 'connection_init') {
        socket.send(
          JSON.stringify({
            type: 'connection_ack',
          })
        )
      } else if (message.type === 'subscribe') {
        const { variables } = message.payload
        subscriptions.push({
          id: message.id,
          variables,
          socket,
        })
      } else {
        console.log(message)
      }
    })
  })

  return {
    onMediaChanged,
  }
}

export function mockWebsocket(url: string): MockServer {
  if (mockServer === null) {
    mockServer = createMockServer(url)
  }

  return mockServer
}
