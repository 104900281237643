import { gql, useMutation } from '@apollo/client'

const renameSlideshowMutation = gql`
  mutation renameSlideshow($id: ID!, $name: String!) {
    renameSlideshow(id: $id, name: $name) {
      id
      name
    }
  }
`

export function useRenameSlideshow() {
  return useMutation<
    any,
    {
      id: string
      name: string
    }
  >(renameSlideshowMutation)
}
