import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MediaFile } from 'src/types'
import { useMediaLibrary } from './useMediaLibrary'

export function useSelectFile() {
  const { files, isLoaded } = useMediaLibrary()

  const { id } = useParams<'id'>()

  const navigate = useNavigate()

  const selectFileById = useCallback(
    (id: string) => {
      navigate(`/media/${id}`)
    },
    [navigate]
  )

  const deselectFile = useCallback(() => {
    navigate('/media')
  }, [navigate])

  const [selectedFile, setSelectedFile] = useState<MediaFile | null>(null)

  useEffect(() => {
    if (!id) {
      setSelectedFile(null)
      return
    }

    if (!isLoaded) {
      return
    }

    const selectedFile = files.find((file) => file.id === id)

    if (!selectedFile) {
      navigate('/media')
      return
    }

    setSelectedFile(selectedFile)
  }, [files, id, isLoaded, navigate])

  return { selectedFile, selectFileById, deselectFile }
}
