import { gql, useQuery } from '@apollo/client'
import { Slideshows } from 'src/types'

const getSlideshowsQuery = gql`
  query getSlideshowsQuery {
    slideshows {
      id
      name
      slides {
        id
        media {
          id
          url
          thumbnailUrl
        }
      }
    }
  }
`

interface SlideshowsResult {
  slideshows: Slideshows
}

export function useSlideshowsWithSlides() {
  return useQuery<SlideshowsResult>(getSlideshowsQuery)
}
