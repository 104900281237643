import { ReactElement, useCallback } from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { device } from 'src/device'
import { useAddSlideshow } from 'src/hooks/api/mutations/useAddSlideshow'
import { Slideshows } from 'src/types'
import { PlaylistItem } from './PlaylistItem'
import { AddPlaylist } from './add-playlist/AddPlaylist'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
`

const PlaylistItemWrapper = styled.div`
  margin-bottom: 30px;
  padding: 0 15px;
  flex: 1 1 100%;
  max-width: ${100 / 3}%;

  @media ${device.tablet} {
    max-width: 50%;
  }

  @media ${device.mobile} {
    max-width: 100%;
  }
`

const StyledAddPlaylist = styled(AddPlaylist)`
  min-height: 200px;
`

export interface Props {
  playlists?: Slideshows
  onSelect?: (playlistId: string) => void
}

export function PlaylistList({ playlists, onSelect }: Props): ReactElement {
  const [addSlideshowMutation, addSlideshowResult] = useAddSlideshow()

  const navigate = useNavigate()

  const addPlaylist = useCallback(async () => {
    if (addSlideshowResult.loading) {
      return
    }

    const date = moment().format('YYYY-MM-DD HH:mm:ss')
    const name = `Playlist - ${date}`

    const { data } = await addSlideshowMutation({
      variables: {
        name,
      },
    })

    if (!data) {
      throw new Error('Failed to add slideshow')
    }

    const {
      addSlideshow: { id },
    } = data
    navigate(`/slideshows/${id}`)
  }, [addSlideshowMutation, addSlideshowResult, navigate])

  return (
    <Container>
      {playlists?.map((playlist) => (
        <PlaylistItemWrapper key={`playlist-${playlist.id}`}>
          <PlaylistItem playlist={playlist} onSelect={onSelect} />
        </PlaylistItemWrapper>
      ))}
      <PlaylistItemWrapper key={`add-playlist`}>
        <StyledAddPlaylist onAddPlaylist={addPlaylist} />
      </PlaylistItemWrapper>
    </Container>
  )
}
