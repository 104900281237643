import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { SubTitle } from 'src/elements/SubTitle'
import { MediaFile } from 'src/types'
import { ThumbnailList } from './ThumbnailList'
import { Uploader } from './Uploader'
import { UploadStatusPopup } from './upload-status/UploadStatusPopup'
import { useMediaLibrary } from './useMediaLibrary'

const StyledUploader = styled(Uploader)`
  margin-bottom: 30px;
`

const StyledSubTitle = styled(SubTitle)`
  margin-top: 0;
  margin-bottom: 30px;
`

function sortMediaByDate(a: MediaFile, b: MediaFile) {
  return !a?.created ? 1 : !b?.created ? 1 : b.created - a.created
}

interface Props {
  onFileSelected?: (fileId: string) => void
}

export function MediaLibrary({ onFileSelected, ...props }: Props) {
  const {
    isLoaded,
    uploadFiles,
    files,
    fileCount,
    finishedFileCount,
    progress,
    resetProgress,
  } = useMediaLibrary()

  const { t } = useTranslation()

  return (
    <div {...props}>
      <StyledSubTitle>
        {isLoaded ? (
          t('mediaLibrary.count', {
            count: files.length,
          })
        ) : (
          <>&nbsp;</>
        )}
      </StyledSubTitle>
      <StyledUploader onFilesSelected={uploadFiles} />
      <ThumbnailList
        files={files.sort(sortMediaByDate)}
        onSelect={onFileSelected}
      />
      <UploadStatusPopup
        fileCount={fileCount}
        finishedFileCount={finishedFileCount}
        progress={progress}
        onHide={resetProgress}
      />
    </div>
  )
}
