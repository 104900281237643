import { gql, useApolloClient, useMutation } from '@apollo/client'

const addScreenMutation = gql`
  mutation addScreen($name: String!) {
    addScreen(name: $name) {
      id
      name
    }
  }
`

export function useAddScreen() {
  const client = useApolloClient()
  return useMutation<
    any,
    {
      name: string
    }
  >(addScreenMutation, {
    onCompleted: () => {
      client.refetchQueries({
        include: 'active',
      })
    },
  })
}
