import React from 'react'
import styled from 'styled-components'
import { mobile, tablet } from 'src/device'
import { MediaFile } from 'src/types'
import { Thumbnail } from './Thumbnail'

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
`

const Col = styled.div`
  width: 100%;
  flex-basis: ${100 / 6}%;
  max-width: ${100 / 6}%;
  padding: 0 5px 10px;

  @media ${tablet} {
    flex-basis: ${100 / 4}%;
    max-width: ${100 / 4}%;
  }

  @media ${mobile} {
    flex-basis: ${100 / 2}%;
    max-width: ${100 / 2}%;
  }
`

export interface Props {
  files: MediaFile[]
  onSelect?: (id: string) => void
}

export function ThumbnailList({ files = [], onSelect }: Props) {
  return (
    <Row>
      {files.map((file) => (
        <Col key={file.id}>
          <Thumbnail file={file} onSelect={onSelect} />
        </Col>
      ))}
    </Row>
  )
}
