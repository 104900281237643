import { ReactElement, useCallback } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import styled from 'styled-components'
import { Slide } from 'src/types'
import { PlaylistItem } from './PlaylistItem'

const StyledPlaylistItem = styled(PlaylistItem)`
  margin-bottom: 15px;
`

interface WrapperProps {
  isActive: boolean
  transition: string | null
}

interface WrapperAttributes {
  transformX: number
  transformY: number
}

const Wrapper = styled.div.attrs<WrapperAttributes>(
  ({ transformX, transformY }) => ({
    style: {
      transform: `translate(
        ${transformX}px,
        ${transformY}px
      )`,
    },
  })
)<WrapperProps & WrapperAttributes>`
  position: relative;
  ${(props) => (props.transition ? `transition: ${props.transition}` : '')};
  z-index: ${(props) => (props.isActive ? 1000 : 0)};
  outline: none;
  user-select: none;
`

interface Props {
  id: string
  slide: Slide
  onDelete: (id: string) => void
  onSelectImage: (id: string) => void
}

export function SortableItem({
  id,
  slide,
  onDelete,
  onSelectImage,
  ...props
}: Props): ReactElement {
  const { active, attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id })

  const isActive = active?.id === id

  const deleteItem = useCallback(() => {
    onDelete(id)
  }, [onDelete, id])

  const changeImageForItem = useCallback(() => {
    onSelectImage(id)
  }, [onSelectImage, id])

  return (
    <Wrapper
      ref={setNodeRef}
      isActive={isActive}
      transformX={transform?.x ?? 0}
      transformY={transform?.y ?? 0}
      transition={transition}
      {...attributes}
      {...props}
    >
      <StyledPlaylistItem
        hasShadow={isActive}
        item={slide}
        dragHandleProps={listeners}
        onDelete={deleteItem}
        onImageClick={changeImageForItem}
      />
    </Wrapper>
  )
}
