import { gql, useApolloClient, useMutation } from '@apollo/client'
import { ScreenId } from 'src/types'

const registerDeviceMutation = gql`
  mutation registerDevice($screenId: ID!, $code: String!) {
    registerDevice(screenId: $screenId, userCode: $code) {
      deviceCode
    }
  }
`

export function usePair() {
  const client = useApolloClient()

  return useMutation<
    any,
    {
      screenId: ScreenId
      code: string
    }
  >(registerDeviceMutation, {
    onCompleted: () => {
      client.refetchQueries({
        include: 'active',
      })
    },
  })
}
