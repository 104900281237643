import styled from 'styled-components'
import { AnimatingModal } from 'src/elements/AnimatingModal'
import { MediaFile } from 'src/types'
import { FilePreview } from './FilePreview'

const StyledAnimatingModal = styled(AnimatingModal)`
  display: flex;
`

export type FilePreviewModalProps = {
  file: MediaFile | null
  onClose?: () => void
}

export function FilePreviewModal({ file, onClose }: FilePreviewModalProps) {
  return (
    <StyledAnimatingModal
      show={file !== null}
      onClose={onClose}
      animatingKey={file ? `file-${file.id}` : 'none'}
      autoSize
    >
      {file && <FilePreview file={file} onClose={onClose} />}
    </StyledAnimatingModal>
  )
}
