import { gql, useMutation } from '@apollo/client'
import { ScreenId, SlideshowId } from 'src/types'

const setSlideshowMutation = gql`
  mutation setSlideshow($screenId: ID!, $slideshowId: ID) {
    setSlideshow(screenId: $screenId, slideshowId: $slideshowId) {
      id
      slideshow {
        id
      }
    }
  }
`

interface Variables {
  screenId: ScreenId
  slideshowId: SlideshowId | null
}

export function useSetSlideshow() {
  return useMutation<any, Variables>(setSlideshowMutation)
}
