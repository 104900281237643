import { Suspense } from 'react'
import { FlagsProvider } from 'react-unleash-flags'
import { InnerApp } from './InnerApp'
import { useConfig } from './config/useConfig'

export function App() {
  const config = useConfig()
  const { featureFlags } = config || {}

  return (
    <Suspense fallback="loading">
      {featureFlags && (
        <FlagsProvider config={featureFlags}>
          <InnerApp />
        </FlagsProvider>
      )}
    </Suspense>
  )
}
