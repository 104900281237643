import { ThemeProvider } from 'styled-components'
import { ConfiguredApp } from './ConfiguredApp'
import { GlobalStyleHelper as GlobalStyle } from './GlobalStyle'
import { useConfig } from './config/useConfig'
import { useIsFlagsLoaded } from './hooks/useIsFlagsLoaded'
import { theme } from './theme'

export function InnerApp() {
  const isFlagsLoaded = useIsFlagsLoaded()
  const config = useConfig()

  return isFlagsLoaded && config ? (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ConfiguredApp config={config} />
    </ThemeProvider>
  ) : null
}
